@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--mouseTopPositon: 0px;
	--mouseLeftPositon: 50px;
	--scrollbarStatus: 'hidden';
	--navStyle: 'difference';
	--scrollCanvas: none;

	--mainContentDisplay: flex;
	--mainContentVisibility: visible;
	--docIsReadyDisplay: flex;
	--docIsReadyVisibility: visible;
}

html {
	padding: 0;
	max-width: 100vw;
	margin: 0;
	background-color: #030303;
}

body {
	background-color: #030303;
	padding: 0;
	max-width: 100vw;
	margin: 0;
}

@media only screen and (max-width: 767px) {
	html,
	body {
		overflow-x: hidden;
	}
}

.hScreen {
	height: calc(100dvh);
}
.h-screen {
	height: calc(100dvh);
}

@layer base {
	@font-face {
		font-family: 'saol';
		src:
			local(''),
			url('/fonts/saol.woff') format('woff');
		font-display: swap;
	}
	@font-face {
		font-family: 'poppins';
		src:
			local(''),
			url('/fonts/poppins.woff') format('woff');
		font-display: swap;
	}

	.text-hp1 {
		@apply text-[11vw] md:text-[6vw] lg:text-[5vw];
	}
	.text-hp2 {
		@apply text-[10vw] md:text-[4.25vw];
	}
	.text-hp3 {
		@apply text-[9vw] sm:text-[8vw] md:text-[4vw];
	}
	.text-hp4 {
		@apply text-[6vw] md:text-[2.8vw];
	}
	.text-hp5 {
		@apply text-[4.2vw] sm:text-[3.2vw] md:text-[2.5vw] lg:text-[2vw] xl:text-[2vw];
	}
	.text-hp6 {
		@apply text-[4.1vw] sm:text-[3.1vw] md:text-[1.65vw] lg:text-[1.5vw] xl:text-[1.3vw];
	}

	.text-hp7 {
		@apply text-[4vw] sm:text-[3vw] md:text-[1.65vw]  lg:text-[1.5vw] xl:text-[1.25vw];
	}
	.text-hp8 {
		@apply text-[3.8vw] sm:text-[2.6vw] md:text-[1.6vw]  lg:text-[1.3vw] xl:text-[1.05vw];
	}
	.text-hp9 {
		@apply text-[3.5vw] xs:text-[3vw] sm:text-[2.25vw] md:text-[1.5vw] lg:text-[1.1vw] xl:text-[0.9vw];
	}
	.text-hp10 {
		@apply text-[3.35vw] xs:text-[2vw] sm:text-[1.8vw] md:text-[1.3vw] lg:text-[1vw] xl:text-[0.8vw];
	}

	.text-hp11 {
		@apply text-[2.5vw] xs:text-[1.8vw] sm:text-[1.5vw] md:text-xs;
	}

	@media (min-width: 768px) {
		.buttonsStyle {
			@apply hover:cursor-pointer hover:scale-105 hover:opacity-80   transition duration-300;
		}
	}

	.buttonsStyleDark {
		@apply hover:cursor-pointer md:hover:scale-105   transition duration-300;
	}
}

.navIndexOne {
	z-index: 9999;
}
.navIndexTwo {
	z-index: 9998;
}

/*-----------------------Scoller-icon*/
.circle-1,
.circle-2 {
	animation: scroll 2s infinite linear;
	opacity: 0;
}

.circle-2 {
	animation-delay: 1s;
}

@keyframes scroll {
	0% {
		cy: 4;
		opacity: 0;
		transform: translate(0vh, -0.2vh);
	}
	45%,
	55% {
		opacity: 0.1;
		cy: 9;
	}
	100% {
		cy: 14;
		opacity: 1;
		transform: translate(0vh, 0.5vh);
	}
}

/*---------------------------------scrollbar--*/
::-webkit-scrollbar {
	width: 0.5em;
}

::-webkit-scrollbar-track {
	background: hsl(0, 0%, 1%);
	mix-blend-mode: difference;
}

::-webkit-scrollbar-thumb {
	background: hsl(0, 0%, 50%);
	border: 0.1em solid hsl(0, 0%, 1%);
	border-radius: 0vw;
}

::-webkit-scrollbar-thumb:hover {
	background: hsla(0, 0%, 50%, 0.8);
}
/*---------------------------------scrollbar firfox--*/
@supports (scrollbar-color: black white) {
	* {
		scrollbar-color: hsla(0, 0%, 50%, 0.8) hsl(0, 0%, 1%);
		scrollbar-width: thin;
	}
}

/*-------------navbar------------*/

.navBlendMode {
	mix-blend-mode: var(--navStyle);
}

/***************** svg ***************/
svg {
	display: inline-block;
	height: 100%;
}

.specialPath {
	z-index: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	overflow: hidden;
}

/***********************************/
.slide {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 100px;
}

.blocHorizontal {
	display: flex;
	flex-direction: row;
	width: 400vw;
	transform: rotate(90deg) translateY(-100vh);
	transform-origin: top left;
}

::-webkit-scrollbar {
	display: none;
}
.one {
	background: #b1f8f2;
}
.two {
	background: #fffc99;
}
.three {
	background: #eafdcf;
}
.four {
	background: #8e8358;
}

.contactplaceholder::placeholder {
	font-size: '2px';
}
.my-input::placeholder {
	font-size: 12px;
}

.my-input {
	font-size: 14px;
	text-transform: capitalize;
}

.input-newsletter::placeholder {
	font-size: 12px;
	padding-left: 5px;
}
.input-newsletter {
	font-size: 12px;
	padding-left: 12px;
}

canvas {
	touch-action: none;
}

svg {
	color: 'red';
}

.pageLoading {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(3, 3, 3, 0.7);
	z-index: 9999;
}

@media (max-width: 767px) {
	.responsive-iframe {
		height: 300px; /* Or whatever height you find suitable */
		width: 800px;
	}
}
@media (min-width: 768px) {
	.responsive-iframe {
		width: 800px; /* Or whatever fixed width you desire */
		height: 400px;
	}
}

.extWrap {
	overflow: hidden;
}

.extElements {
	width: fit-content;
	display: flex;
	flex-wrap: nowrap;
}

.threeDs {
	perspective: 1500px;
}
