.herox {
  z-index: 99999;
}

.herobg {
  background-image: url(/hero/bg2.jpg);
  background-clip: text;
  --webkit-background-clip: text;
  color: transparent;
}

.vertical-text {
  writing-mode: vertical-rl;
  letter-spacing: -0px; /* Ajustez cette valeur selon vos besoins */
  text-orientation: mixed;
}

.single-line {
  white-space: nowrap;
}
