:root {
 --afterPourcentage-height: 180px;
 --Pourcentage-color: rgba(255, 255, 255, 0.247);
 --afterPourcentage-color: rgb(255, 255, 255);
 --textPourcentage-color: rgb(255, 255, 255);
 --Pourcentage-visibility: hidden;
}

.pourcentage {
 height: 180px;
 width: 1px;
 display: block;
 background-color: var(--Pourcentage-color);
 top: 0;
 right: 0;
 border-radius: 10px;
}

.pourcentage::after {
 width: 1px;
 height: var(--afterPourcentage-height);
 display: block;
 content: '';
 background-color: var(--afterPourcentage-color);
 transition: height 1s ease-out;
 border-radius: 10px;
}

.pourcentage-countainer {
 z-index: 9989;
 visibility: var(--Pourcentage-visibility);
 pointer-events: none;
 user-select: none;
 -moz-user-select: none;
 -webkit-user-select: none;
 -ms-user-select: none;
}

.textPourcentage {
 color: var(--textPourcentage-color);
}
/*
getComputedStyle(document.documentElement).getPropertyValue('--afterPourcentage-height')

document.documentElement.style.setProperty('--afterPourcentage-height, height')
*/
