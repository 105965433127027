.gallery {
  display: flex;
}

.left {
  width: 50%;
}

.right {
  height: 100vh;

  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.desktopContent {
  margin: auto;
  width: 80%;
}

.desktopContentSection {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.desktopPhotos {
  width: 36vw;
  height: 36vw;
  border-radius: 1px;
  position: relative;
  overflow: hidden;
}

.desktopPhoto {
  position: absolute;
  width: 100%;
  height: 100%;
}

.red {
  background-image: url(/img/inter2.jpg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
}
.pink {
  background-image: url(/img/inter1.jpg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
}

.blue {
  background-image: url(/img/inter3.jpg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain;
}

/* small screen / mobile layout */
.mobileContent {
  display: none;
  width: 80vw;
}

.mobilePhoto {
  width: 80vw;
  height: 80vw;
  margin-top: 4em;
  border-radius: 1px;
}

/* defines styles for screens up to 599px wide */
@media screen and (max-width: 599px) {
  .left {
    display: none;
  }

  .right {
    height: auto;
    width: 100%;
    align-items: center;
  }

  .desktopPhotos {
    display: none;
  }

  .mobileContent {
    display: block;
  }
}
