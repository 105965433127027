.carteText {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 90;
}

.carteVisit1 {
  border-radius: 10% / 50%;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(-75deg);
  z-index: 100;
}

.carteVisit2 {
  top: 10%;
  left: 30%;
  transform: translate(-50%, -50%);
  transform: rotate(85deg);
  z-index: 101;
}

.carteVisit3 {
  top: 40%;
  left: 30%;
  transform: translate(-50%, -50%);
  transform: rotate(105deg);
  z-index: 99;
}

.carteVisit4 {
  top: 40%;
  left: 45%;
  transform: translate(-50%, -50%);
  transform: rotate(-15deg);
  z-index: 98;
}

.idVisitIndex {
  z-index: 160;
}

.idPartnerIndex {
  z-index: 120;
}

.idSpliterIndex {
  z-index: 150;
}

.carteVisitMobileContainer {
  height: min-content;
}
